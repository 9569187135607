
.block-emphasize, blockquote{
	margin: 15px auto;
	margin-left: auto !important;
	margin-right: auto !important;
	font-size: 18px;
	color: $apejs_purple;
	text-align: center;
	max-width: 700px;
}
