
// Theme APEJS
$apejs_darkblue: #101D2F;
$apejs_lightblue: #00A09A;
$apejs_orange: #e09a08;
$apejs_purple: #A2195B;
$apejs_silver: #e6e7e8;
$apejs_gray: #868686;

@import "fonts";
@import "header";
@import "separator";
@import "big-link";
@import "square-menu";
@import "banner";
@import "breadcrumb";
@import "partners";
@import "footer";
@import "pink-columns";
@import "services";
@import "please-note";
@import "emphasize";
@import "diptyc";
@import "events";

body{
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 14px;
	overflow-x: hidden;
}
a{
	color: lighten($apejs_darkblue, 10);
	text-decoration: none;
	transition: all .07s;

	&:hover, &:focus{
		color: $apejs_lightblue;
	}
}

h1{
	margin: 60px 0 40px 0;
	font-size: 24px;
	color: $apejs_purple;
	text-transform: uppercase;
}
h2{
	margin: 60px 0 40px 0;
	font-size: 24px;
	color: $apejs_purple;
	text-transform: uppercase;
}
h3{
	margin: 40px 0 20px 0;
	font-size: 18px;
	color: $apejs_lightblue;
	text-transform: uppercase;
}
h4{
	margin: 30px 0 10px 0;
	font-size: 16px;
	font-weight: 500;
	color: $apejs_lightblue;
	//text-transform: uppercase;
}

blockquote{
	margin: 5px 0;
	font-size: 18px;
	color: $apejs_purple;
}

img{
	max-width: 100%;
}

//.block-pink-columns,
.cms-page-block[data-type="simple"],
.cms-page-block[data-type="text"]{
	ul{
		list-style: none;
		li::before {
			content: "●";
			color: $apejs_lightblue;
			display: inline-block;
			width: 1em; /* Also needed for space (tweak if needed) */
			margin-left: -1em; /* Also needed for space (tweak if needed) */
		}
	}
}


.line-separated{
	>*:not(:last-child)::after{
		content: "-";
		display: inline-block;
		padding: 0 3px 0 5px;
		text-decoration: none;
	}
}

.slash-separated{
	>*:not(:last-child)::after{
		content: "/";
		display: inline-block;
		padding: 0 3px 0 5px;
		text-decoration: none;
	}
}


.smallblock{
	display: block;
	font-size: small;
	line-height: 1.2em;
}

.page-template{
	background: white;
	position: relative;
	z-index: 100;
	//padding-bottom: 50px;
}

.parallax-mirror{
	pointer-events: none;
}

.anchor{
	display: block;
	height: 0;
	width: 0;
	position:relative;
	bottom: 90px;
}

.cms-page-block{
	// Corriger les margin overflow
	overflow: auto;
}

.leaflet-map{
	height: 350px;
	max-height: 80vh;
}

.apejs-purple{	color: $apejs_purple;	}
.apejs-darkblue{	color: $apejs_darkblue;	}
.apejs-silver{	color: $apejs_silver;	}
.apejs-gray{	color: $apejs_gray;	}
.apejs-orange{	color: $apejs_orange;	}
