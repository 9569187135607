
.please-note{
	background: $apejs_silver;
	color: $apejs_purple;
	padding: 20px 0 20px 0;

	h1, h2, h3{
		margin: 15px 0;
		background: url('../../images/theme-apejs/smalldrop-pink.png') no-repeat left center;
		padding-left: 26px;
	}
}
