
.big-link{
	display: flex;
	align-items: center;

	font-size: 18px;
	margin: 15px 0;

	.label{
		padding-top: 5px;
		text-align: right;
		padding-right: 16px;
	}
	.drop {
		min-height: 36px;
		min-width: 40px;
		background-image: url('../../images/theme-apejs/drop-purple.png');
		background-position: center right;
		background-repeat: no-repeat;
		flex: 0 0 36px;
	}
	.preview{
		padding-left: 16px;

		img{
			max-height: 140px;
			max-width: 140px;
		}
	}

	&.big-link-download .drop{
		background-image: url('../../images/theme-apejs/drop-download-orange.png');
	}
}
