
.cms-page-block[data-type="diptyc"]{

	.diptyc:not(.diptyc-small){
		p{
			font-size: 18px;
			color: $apejs_lightblue;
		}
		ul{
			list-style-type: none;
		}
		li{
			//list-style-image: url('../../images/theme-apejs/smalldrop-lightblue.png');
		}
		li::before{
			position: relative;
			top: 20px;
			content: " ";
			display: block;
			width: 18px;
			height: 18px;
			right: 25px;
			background: url('../../images/theme-apejs/smalldrop-lightblue.png') no-repeat center;
		}
	}
}
