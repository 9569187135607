
.square-menu{
	margin: 30px 0;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;

	font-size: 16px;

	&.by-2{
		font-size: 18px;
		justify-content: flex-start;
		> *{
			flex: 0 1 340px;
		}
	}

	&.by-4{
		font-size: 18px;
	}

	> *{
		flex: 1;
		background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);

		> a{
			position:relative;
			display: block;
			padding-top: 100%;
			background-size: cover;
			background-position: center;
			text-shadow: 0 0 black;
			transition: text-shadow .3s ease-out;

			>* {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 20px;
				text-align: center;

				color: white;
				font-weight: 500;
				text-transform: uppercase;

				transition: background .3s ease-out;

				&.orange{ 		background: transparentize($apejs_orange, 0.2);	}
				&.purple{ 		background: transparentize($apejs_purple, 0.2);	}
				&.lightblue{ 	background: transparentize($apejs_lightblue, 0.2);	}
				&.darkblue{ 	background: transparentize($apejs_darkblue, 0.2);	}
			}


			&:hover, &:focus{
				text-shadow: 0 0 5px rgba(0, 0, 0, 0.9), 0 0 35px rgba(0, 0, 0, 1), 0 0 20px rgba(0, 0, 0, .2);
				>* {
					&.orange{ 		background: transparentize($apejs_orange, 0.1);	}
					&.purple{ 		background: transparentize($apejs_purple, 0.1);	}
					&.lightblue{ 	background: transparentize($apejs_lightblue, 0.1);	}
					&.darkblue{ 	background: transparentize($apejs_darkblue, 0.1);	}
				}
			}
		}
	}
}

@include media-breakpoint-down(lg) {
	.square-menu{
		display: block;

		> * > a{
			padding: 0 !important;

			> *{
				position: static;
			}
		}
	}
}
