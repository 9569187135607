$transparent_bg: rgba(255,255,255,.9);
$header_transition_duration: .5s;
$header_transition_easing: ease-out;
$header_transition: all $header_transition_duration $header_transition_easing;
$light_border: 1px solid #eeeeee;
$transparent_border: 1px solid rgba(255,255,255,0);

#topbar{
	position: relative;
	background: white;
	padding: 18px 0 6px;
	font-size: 18px;

	[class^="col-"]:not(:last-child){
		margin-left: 10px;
		margin-right: 10px;
	}

	a{
		color: inherit;
		transition: all .1s;

		&:hover, &:focus, &.active{
			color: $apejs_lightblue;
		}
	}

	.social{
		a{
			display: inline-block;
			margin: 0 3px;
			padding: 0 3px;
		}
	}

	.quicklinks{
		a{
			opacity: .7;
		}
	}


}

#main_menu_wrapper{
	position: sticky;
	z-index: 500;
	top: 0;
	height: 0;
}

#main_menu{
	background: $transparent_bg;
	transition: $header_transition;
	border-bottom: $transparent_border;

	.logo{
		display: block;
		margin: 16px 0;

		svg{
			transition: $header_transition;
			height: 80px;
		}
	}

	.transparent-bg-layout{
		align-items: stretch;

	}
}

.menu-toggler-wrapper{
	display: none;
	font-size: 30px;

	a{
		display: inline-block;
		padding: 13px;
	}
}

#main_nav{
	display: flex;
	height: 100%;
	font-size: 18px;
	color: $apejs_darkblue;

	a{
		color: inherit;
		transition: all .1s;

		&:hover, &:focus, &.active{
			color: $apejs_lightblue;
		}
	}

	a.level-0{
		display: inline-block;
		padding: 0 8px;
		position: relative;
		top: 40%;
	}

	a.level-1{
		display: block;
		padding: 5px 0;
	}

	a.level-2{
		display: block;
		padding: 2px 0;
		font-size: 14px;
	}
	a.level-2::before{
		content: "•";
		color: $apejs_lightblue;
	}

	.level-0{
		position: relative;
		z-index: 50;

		&:hover, &.touched{
			.level-1-wrapper{
				opacity: 1;
				pointer-events: all;
			}
			a.level-0{
				color: $apejs_lightblue;
			}
		}
	}

	.level-1-wrapper{
		opacity: 0;
		pointer-events: none;

		position: absolute;
		top: calc(100% + 1px);
		right: 0;
		width: 100%;
		min-width: 270px;

		transition: $header_transition, opacity .1s ease-out;
		background: $transparent_bg;
		border: $transparent_border;
		border-top: $transparent_border;
		padding: 0 16px 8px 16px;

		>* {
			position: relative;
			bottom: 10px;
		}
	}

	.level-2-wrapper{
		padding-bottom: 6px;
	}
}

// Specials
#main_nav .menu-item.level-0[data-parameters="la-soute"] {
	//align-self: flex-end;
	//z-index: 10;

	> a{
		background-image: url('../../images/theme-apejs/logo-soute-white.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: auto 65%;
		background-color: $apejs_lightblue;
		border-top-left-radius: 0;
		border-top-right-radius: 100%;
		border-bottom-right-radius: 100%;
		border-bottom-left-radius: 100%;
		width: 70px;
		height: 70px;

		float: left;

		span{
			display: none;
		}
	}
}

@include media-breakpoint-down(xl) {
	#main_nav .menu-item.level-0[data-parameters="la-soute"] a {
		width: 55px;
		height: 55px;
	}
}
@include media-breakpoint-down(lg) {
	#main_nav .menu-item.level-0[data-parameters="la-soute"] a {
		width: calc(100% - 40px);
		border-radius: 0 !important;
		margin: 10px 20px;
		color: white;
		background-size: auto 80px;
		min-height: 100px;
		//background-position: top center;
		display: block;
	}
}


// Sticky
body.sticky-menu{
	#main_menu{
		background: white;
		border-bottom: $light_border;
		.logo svg{
			height: 35px;
		}
	}
	#main_nav .level-1-wrapper{
		background: white;
		border: $light_border;
		border-top: $transparent_border;
		top: 100%;
	}
}

@include media-breakpoint-down(xxl) {
	#main_menu .logo svg{
		height: 40px;
	}
	#main_nav{
		font-size: 16px;
	}
}
@include media-breakpoint-down(lg) {

	#topbar{
		display: none;
	}
	#main_nav{
		opacity: 0;
		pointer-events: none;
		transition: all .2s ease-out;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;

		position: fixed;
		top: 0;
		left: 0;
		background: #eaeaea;
		width: 100vw;
		height: 100vh;
		overflow: auto;
		font-size: 22px;
		text-align: center;

		padding: $grid-gutter-width/2 $grid-gutter-width/2 120px;

		a{
			display: block;
			padding: 3px $grid-gutter-width/2;
			//outline: 2px dotted green;
		}

		a.level-0{
			display: block;
			position: static;
			padding: 5px 0;
		}
		a.level-1{
			font-size: .9em;
		}
		a.level-2{
			font-size: .8em;
			opacity: .8;
		}

		.level-1-wrapper{
			pointer-events: inherit;
			position: static;
			opacity: 1;
			margin: 3px 20px;
			width: auto;
			min-width: none;

			> *{
				position: static;
			}
		}
		//color:
	}

	body.menu-open{
		overflow: hidden;
		#main_nav{
			opacity: 1;
			pointer-events: all;
		}
	}

	.menu-toggler-wrapper {
		display: block;
	}
}
