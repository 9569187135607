
.event{
	.event-date{
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: $apejs_purple;
		line-height: 1.5em;
		font-weight: 600;

		.weekday{ }
		.day{ font-size: 2em; }
		.month{ }
	}
}
.event, .event-passed{
	.description{
		>:last-child{
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}
