$partner_max_w: 130px;
$partner_max_h: $partner_max_w;

.block-partners{
	background-size: 100% auto;
	background-position: center;
	padding: 0 0 40px 0;

	&.bg-ivory{
		background-image: url('../../images/theme-apejs/ivory.jpg');
	}
	&.bg-white{
		background-image: none;
	}
	ul, li{
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;
		justify-content: center;
		align-items: center;
	}
	li.partner{
		//max-width: $partner_max_w;
		margin: $grid-gutter-width /2;

		img{
			max-width: $partner_max_w;
			max-height: $partner_max_h;
		}

		>*{
			filter: grayscale(100%);
			transition: all .1s;

			&:hover, &:focus{
				filter: grayscale(0);
			}
		}

		&.has-description{
			max-width: none;
		}

		.description-column{
			max-width: 450px;
		}
	}
	li.category{
		flex: 1 0 100%;
		max-width: 100%;
		text-align: center;
	}
}
