
.separator{
	margin: 60px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;

	>*:first-child,
	>*:last-child{
		flex: 0 0 136px;
		height: 22px;
		background-image: url(../../images/theme-apejs/jack-darkblue.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	>*:last-child{
		transform: rotateY(180deg);
	}
	>*:nth-child(2){
		flex: 1;
		background: $apejs_darkblue;
		height: 2px;
	}

	&.style-purple{
		>*:first-child,
		>*:last-child{
			background-image: url(../../images/theme-apejs/jack-purple.png);
		}
		>*:nth-child(2){
			background: $apejs_purple;
		}
	}
}
