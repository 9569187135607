
.banner{
	position:relative;
	height: 450px;
	max-height: 50vh;
	overflow: hidden;

	> *{
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-position: center;
		background-size: cover;
	}

	border-color: $apejs_lightblue; // Default
	&.style-blue{	border-color: $apejs_lightblue;		}
	&.style-purple{	border-color: $apejs_purple;		}
	&.style-orange{	border-color: $apejs_orange;		}

	&.top-banner:not(.has-h1){
		border-bottom-style: solid;
		border-bottom-width: 18px;
	}
	&.separation-banner{
		height: 315px;

		> *:first-child{
			background-image: none !important;
		}

		.overlay{
			background: lighten($apejs_purple, 20);
		}
	}
}

.banner-h1{
	background: silver;
	color: white;
	text-align: center;

	&.style-blue{	background-color: $apejs_lightblue;		}
	&.style-purple{	background-color: $apejs_purple;		}
	&.style-orange{	background-color: $apejs_orange;		}

	h1{
		margin: 0;
		padding: 6px 0;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 44px;
		color: inherit;
	}
}

.parallax-mirror.separation-banner-mirror{
	opacity: .5;
}
