$nav_w: 300px;
$sideTransition: .2s ease-out;
$containerGutter: 20px;
$appGray: #eaeaea;
$shader-bg: rgba(0,0,0,.5);
$header-h: 70px;
$niceshadow:  0 1px 2px rgba(0,0,0,0.02),
0 2px 4px rgba(0,0,0,0.02),
0 4px 8px rgba(0,0,0,0.02),
0 8px 16px rgba(0,0,0,0.02),
0 16px 32px rgba(0,0,0,0.02),
//0 32px 64px rgba(0,0,0,0.02)
;

// Theme APEJS
$app_darkblue: #101D2F;
$app_lightblue: #00A09A;
$app_orange: #DD9A0C;
$app_purple: #A2195B;


// Bootstrap
$body-bg: #ecf1f7;
$enable-shadows: true;

$modal-fade-transform: scale(.8);

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 1.3; // Header only
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.3;

// scss-docs-start theme-color-variables
$primary:       lighten($app_darkblue, 15);
$secondary:     $app_lightblue;
//$success:       $green;
$info:          $app_lightblue;
$warning:       $app_orange;
//$danger:        $red;
//$light:         $gray-100;
//$dark:          $gray-900;
// scss-docs-end theme-color-variables
