
.services-nav{
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
	margin: 10px 0 20px;

	> * {
		flex-grow: 1;
		background: $apejs_silver;
		margin: 10px 2px 20px;
		position: relative;
	}

	button{
		display: block;
		width: 100%;
		border: 0;
		height: 100%;
		font-size: 24px;
		font-weight: 500;
		line-height: 1.2em;
		padding: 10px 8px;
		background: $apejs_silver;

		&.active, &:hover, &:focus{
			background: darken($apejs_silver, 10);

			.pointer{
				border-top-color:  darken($apejs_silver, 10);;
				top: calc(100% + 5px);
			}
		}

		&.active{
			background-color: $apejs_lightblue;
			color: white;

			.pointer{
				border-top-color : $apejs_lightblue;
				top: calc(100% + 5px);
			}
		}
	}

	.pointer{
		display : block;
		height : 0;
		width : 0;
		border-top : 11px solid transparent;
		border-right : 12px solid transparent;
		border-left : 12px solid transparent;

		transition: all .15s;
		position: absolute;
		top: calc(100% - 15px);
		left: calc(50% - 6px);
	}
}

.service-group{
	.service-group-label{
		text-transform: uppercase;
		font-size: 24px;
	}

	.service{
		position: relative;
		margin-top: 50px;
		margin-bottom: 50px;

		.service-addon{
			width: 55px;
			height: 55px;
			background: url('../../images/theme-apejs/drop-darkblue.png') no-repeat center;
			color:white;
			font-size: 14px;
			line-height: 1em;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			padding: 4px;
			position: absolute;
			right: 0;
			top: -45px;
		}

		.service-header{
			font-size: 18px;
			text-transform: uppercase;
			background: $apejs_purple;
			color: white;
			font-weight: 600;
			text-align: center;
			padding: 10px $grid-gutter-width /2 18px;
			margin: $grid-gutter-width /2 0 6px;

			small{
				display: block;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.service-header::after{
			content: "";
			display: block;
			position: absolute;
			left: calc(50% - 15px);
			margin-top: 6px;
			width: 30px;
			height: 30px;
			background: url('../../images/theme-apejs/drop-pink.png') no-repeat center;
			background-size: cover;
		}

		.service-description{
			background: $apejs_silver;
			padding: 22px $grid-gutter-width 20px $grid-gutter-width;
			margin: 8px 0 10px;
			font-size: 14px;
			//line-height: 1.2em;
			color: #58595b;
		}

		.service-table{
			border-collapse: collapse;
			width: 100%;

			tr{
				color: $apejs_purple;
				background: transparent;

				td, th{
					padding: 10px 6px;
					border: 1px solid $apejs_purple;
					text-align: center;
					font-weight: 500;
					font-size: 16px;
					line-height: 1.2em;
				}

				&:nth-child(even){
					color: white;
					background: $apejs_purple;

					th{
						border-right-color: white;
					}
				}
			}
		}
	}

	.service-group-notes{
		>*{
			background: url('../../images/theme-apejs/bars-purple.png') no-repeat left center;
			background-size: 50px auto;
			padding: 70px;
		}
		h1,h2,h3,h4{
			color: inherit;
		}
	}
}


@include media-breakpoint-down(lg) {
	.services-nav{
		button{
			font-size: 16px;
		}
	}
}
@include media-breakpoint-down(md) {
	.services-nav{
		flex-wrap: wrap;

		>*{
			flex: 1 0 100%;
			margin-top: 0;
			margin-bottom: 1px;
		}
		.pointer{
			display: none;
		}
	}
}
