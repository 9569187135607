
#main_footer{
	background: $apejs_darkblue;
	background-image: url(../../images/theme-apejs/waves-corner-1.png);
	background-position: bottom right;
	background-repeat: no-repeat;
	color: white;
	padding: 60px 0 20px;
	font-size: 18px;

	a{
		color: inherit;

		&:hover, &:focus{
			text-decoration: underline;
		}
	}

	svg{
		margin: 0 2vw 30px 0;
		max-width: 300px;

		path{
			fill: white;
		}
	}

	.punchline{
		color: $apejs_purple;
		font-size: 24px;
		margin: -5px 0 20px;
	}

	.right-column{
		text-align: center;
	}

	.social{
		font-size: 25px;
		a{
			display: inline-block;

			&:not(:last-child){
				margin-right: 6px;
			}

			&:hover, &:focus{
				text-decoration: none;
				color: $apejs_purple;
			}
		}
	}

	.bottom-links{
		font-size: 10px;
		text-transform: uppercase;
		opacity: .7;
		margin-top: 180px;
	}

}

@include media-breakpoint-down(lg) {

	#main_footer{
		font-size: 14px;
		text-align: center;
		background-image: none;

		.punchline{
			font-size: 16px
		}
		.social{
			padding-top: 20px;
		}

		.bottom-links{
			margin-top: 30px;
		}
	}
}
