$arrow-size: 20px;
$arrow-padding: 20px;

.cms-page-block[data-type="jumbo"]{
	background: #d5d5d5;
	position: relative;
	overflow: hidden;

	.jumbo-slides{
		position: relative;
		right: 0;
		transition: all .8s cubic-bezier(.15,0,.41,1.01);
		min-height: calc(100vh - 51px);
		display: flex;
		//overflow:hidden;
	}

	.jumbo-nav{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;

		.jumbo-nav-arrows{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;

			display: flex;
			justify-content: space-between;
			align-items: center;

			a{
				pointer-events: all;
				position:relative;
				flex: 0 0 calc(#{$arrow-size} + 2 * #{$arrow-padding});
				padding: $arrow-padding;

				&:last-child{
					transform: scaleX(-1);
				}

				&:hover, &:focus{
					svg{
						fill: rgba(255,255,255,.7);
					}
				}

				svg{
					transition: all .1s ease-out;
					max-width: $arrow-size;
					fill: rgba(255,255,255,0);
					stroke: #fff;
					stroke-miterlimit: 10;
					stroke-width: 15px;
				}

			}
		}

		.jumbo-nav-dots{
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 0 16px 16px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			font-size: 18px;

			a {
				pointer-events: all;
				display: inline-block;
				background: rgba(255,255,255,0);
				transition: all .15s;
				height: 1em;
				width: 1em;
				margin: .2em;
				border-radius: 1em;
				border: 1px solid white;

				&:hover, &:focus, &.active{
					background: rgba(255,255,255,1);
				}
			}
		}
	}

	.jumbo-slide{
		flex: 0 0 100%;
		position: relative;
		background-position: center;
		background-size: cover;

		.jumbo-content{
			margin: (112px + 40px) 40px 40px 40px;
			max-width: 320px;
			color: white;
			text-shadow: 0 0 5px black;
			font-size: 14px;
			line-height: 18px;

			a{
				color: inherit;
				text-decoration: none;
				font-weight: bold;
			}
		}

		.jumbo-content-header{
			font-size: 24px;
			line-height: 30px;
			text-transform: uppercase;
			font-weight: 500;
			margin: 0 0 18px;
		}

		.jumbo-content-header, .jumbo-content-text{
			position: relative;
			top: 0;
			opacity: 1;
			transition: all .8s ease-out;
			transition-delay: .8s;

			&.jumbo-content-text{
				transition-delay: 1.2s;
			}
		}

		&:not(.active){
			.jumbo-content-header{
				top: -10%;
				opacity: 0;
			}
			.jumbo-content-text{
				top: 10%;
				opacity: 0;
			}
		}


	}

	.jumbo-content-wrapper{
		position: relative;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-right: 10vw;

		background: linear-gradient(270deg, rgba(0,0,0,.8) 0, rgba(0,0,0,0) 800px);
	}

	.waves-overlay{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 50vw;
		padding-top: 29%;
		background-image: url('../images/theme-apejs/waves1.png');
		background-position: top right;
		background-size: 118%;
		//background-color: red; // test
	}

}
