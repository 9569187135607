
.cms-page-block[data-type="breadcrumbs"]{
	margin: 16px 0;

	a, span{
		color: inherit;

		&:last-child{
			color: $apejs_purple;
		}
	}
}
